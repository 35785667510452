/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl, FormattedMessage, Link } from '@intractive/gatsby-plugin-react-intl'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import Helmet from 'react-helmet'
import Layout from '../Layout'

const style = require("./index.module.css");

const propTypes = {
  data: PropTypes.object.isRequired,
}

function auteurcheck(auteur) {
  if (auteur != null) {
    return auteur
  }
}

function splitByNewlineAndGetLast(srcset) {
    let srcs = srcset.split('\n');
    let last = srcs[srcs.length-1];
    let url = last.split(' ')[0];
    return url;
}

function Greeting() {
  const isLoggedIn = props.isLoggedIn
  if (isLoggedIn) {
    return <UserGreeting />
  }
  return <GuestGreeting />
}

class BlogTemplate extends React.Component {
  render() {
    const article = this.props.data.contentfulBlogartikel
    const location = this.props.location
    const {
      title,
      slug,
      date,
      migrationId,
      metaDescription,
      lead,
      body,
      image,
      auteur,
      bijbehorendePortefeuilles,
    } = article

    const locale = this.props.location.pathname.startsWith("/en/") ? "/en/" : "/";

    const url = migrationId
      ? `${locale}actueel/${migrationId}/${slug}/`
      : `${locale}actueel/${slug}/`
    const backUrl =
      location.state &&
      location.state.parentUrl &&
      1 !== location.state.parentUrl
        ? `/actueel/${location.state.parentUrl}/`
        : `/actueel/`

    return (
      <Layout>
        <Helmet>
          <title>{title} · STIP</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={metaDescription} />
          {migrationId ? (
            <meta
              property="og:url"
              content={
                'https://www.stipdelft.nl' +
                locale +
                'actueel/' +
                migrationId +
                '/' +
                slug +
                '/'
              }
            />
          ) : (
            <meta
              property="og:url"
              content={'https://www.stipdelft.nl' + locale + 'actueel/' + slug + '/'}
            />
          )}
          <meta property="og:type" content="article" />
          <meta property="og:locale" content="nl_NL" />
          {image && (
            <meta
              property="og:image"
              itemprop="image"
              content={splitByNewlineAndGetLast(image.gatsbyImageData.images.sources[0].srcSet)}
            />
          )}
          {image && (
            <meta
              property="og:image:secure_url"
              content={splitByNewlineAndGetLast(image.gatsbyImageData.images.sources[0].srcSet)}
            />
          )}
        </Helmet>
        <div className="main-content row">
          <div className="large-2 hide-for-medium columns sidebar hide-for-small">
            <Link to={backUrl} className="blog-back-link">
               ← <FormattedMessage id="nieuwsoverzicht" />
            </Link>
            {bijbehorendePortefeuilles && (
              <span>
                <br/>
                <br/>
                <strong><FormattedMessage id="Portefeuille" />:</strong>
              </span>
            )}
            {bijbehorendePortefeuilles &&
              bijbehorendePortefeuilles.map(function(porto, index) {
                const portefeuille = porto
                const url = `/portefeuilles/${portefeuille.slug}/`
                return (
                  <Link key={index} to={url}>
                    <span itemProp="portefeuillenaam">
                      <br/>
                      {portefeuille.titel}
                    </span>
                  </Link>
                )
              })}
          </div>
          <article
            className="blog-article large-7 medium-9 columns"
            itemScope=""
            itemType="http://schema.org/Article"
          >
            <div className="row">
              <div className="large-12 columns blog">
                <h1 className="blog-title" itemProp="name">
                  {title}
                </h1>

                <div itemProp="articleBody" className="content">
                  {lead && (
                    <div
                      className="blog-lead"
                      dangerouslySetInnerHTML={{
                        __html: lead.childMarkdownRemark.html,
                      }}
                    />
                  )}

                  {image && (
                    <GatsbyImage image={image.gatsbyImageData} alt={image.title} className={style.image} />
                  )}

                  {body && (
                    <div className="blog-body">
                      <span id="more" className="blog-more-anchor"></span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: body.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  )}
                </div>

                <footer className="blog-footer">
                  <time
                    className="blog-time inline"
                    itemProp="dateCreated"
                    dateTime={date}
                  >
                    {date}
                  </time>
                  {/* <div className="right inline">Deel dit artikel op <a href="">Facebook</a> of <a href="">Twitter</a></div> */}
                  <a
                    itemProp="url"
                    href={`https://www.stipdelft.nl/${url}`}
                    className="hidden"
                  >

                    <FormattedMessage id="Permalink"/>
                  </a>
                </footer>
              </div>
            </div>
          </article>
          <div className="large-3 medium-3 small-12 auteurbar columns">
            {auteur ? (
              <Link to={`/mensen/${auteur.slug}`}>
                {auteur.image ? (
                  <div className="auteur-foto">
                    <GatsbyImage
                      image={auteur.image.gatsbyImageData}
                      className="round-image profielfoto"
                      itemProp="image"
                      alt={auteur.name} />
                  </div>
                ) : (
                  <div className="auteurfoto">
                    <img
                      className="round-image profielfoto"
                      src="/images/placeholder.jpg"
                      alt="Placeholder"
                    />
                  </div>
                )}
                <div className="auteur-name" itemProp="name">
                  {auteur.name}
                </div>
                {auteur.jobtitle && (
                  <div className="auteur-jobTitle" itemProp="jobTitle">
                    {auteur.jobtitle}
                  </div>
                )}
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

BlogTemplate.propTypes = propTypes

export default injectIntl(BlogTemplate)

export const pageQuery = graphql`
  query($slug: String!, $node_locale: String) {
    contentfulBlogartikel(slug: { eq: $slug }, node_locale: {eq: $node_locale}) {
      title
      slug
      date(formatString: "DD - MM - YYYY")
      migrationId
      metaDescription
      lead {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        title
        gatsbyImageData(formats: WEBP, width: 1000)
      }
      auteur {
        name
        jobtitle
        slug
        image {
          title
          gatsbyImageData(formats: WEBP, width: 500)
        }
      }
      bijbehorendePortefeuilles {
        slug
        titel
      }
    }
  }
`
